
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import getT from 'next-translate/getT';
import SharedLibs from 'wizarding-world-web-shared';
import { NEXT_LOCALE_COOKIE } from '@constants/localization';
import { setAuthSuccessRedirect } from '@utils/authSuccessRedirect';
import LanguagePicker from '@/src/components/LanguagePicker';
import Logo from '@/src/components/Logo';
import Button from '@/src/components/Button';
import HeadMeta from '../../components/HeadMeta';
import Image from '../../components/Image';
import AnchorLink from '../../components/AnchorLink';
import s from './SortingHatIntro.module.scss';
import SegmentPage from '../../components/SegmentPage';
import Loaders from '../../components/Loaders';
import loaderTypes from '../../components/Loaders/loaderTypes';
import Redirect from '../../components/Redirect';
import { trackEvent } from '../../utils/analytics';
import { optimizeImageSet } from '../../utils/optimizeImage';
import { useUserContext } from '../../contexts/user-context';
import sortingHatIMGS from './assets/sorting-hat.png?resize&sizes[]=1440&sizes[]=768&sizes[]=312';
import { SORTING_HAT, PROFILE, SORTING_HAT_FIRST_QUESTION, WW_SORTING_HAT_DIRECT, LOCALIZED_HOUSE_RESULTS, } from '../../constants/routes';
const metaData = {
    ogTitle: 'Take part in our official Hogwarts House Sorting Ceremony!',
    ogDescription: 'Discover if you belong in Gryffindor, Ravenclaw, Slytherin or Hufflepuff.'
};
const pageName = 'Sorting Hat Intro';
const Intro = () => {
    const { getCookie } = SharedLibs.utils.cookie;
    const { profile, loading, loadingProfile, isLoggedIn } = useUserContext();
    const { t, lang } = useTranslation('sorting-intro');
    const [buttonLabel, setButtonLabel] = useState('');
    useEffect(() => {
        const getEnglishButtonLabel = async () => {
            const defaultT = await getT('en', 'sorting-intro');
            setButtonLabel(defaultT('button'));
        };
        getEnglishButtonLabel();
    }, []);
    // BB: this fixes a race condition in WWD-3019 where lang is always 'en'
    // the first time a localized user hits /sorting-hat before they are
    // redirected by LocaleRouter to the correct localized route; in that
    // case the user is treated as an english speaking user and erroneously
    // redirected to /profile.
    // If we start adding redirects based on lang elsewhere then this should
    // be moved into a hook (perhaps a wrapper around useTranslation that
    // adds a loading state).
    let loadingLocale = false;
    if (process.env.REACT_APP_LOCALIZATION_PHASE_1_ENABLED === 'TRUE') {
        loadingLocale = getCookie(NEXT_LOCALE_COOKIE) !== lang;
    }
    if (loading || loadingProfile || loadingLocale || !buttonLabel) {
        return <Loaders type={loaderTypes.timeturner}/>;
    }
    if (isLoggedIn && profile.hogwartsHouse) {
        // send already sorted, non-english users to the localized house results page
        // instead of the non-localized profile page
        const redirectUrl = lang !== 'en'
            ? `${LOCALIZED_HOUSE_RESULTS}/${profile?.hogwartsHouse?.toLowerCase()}`
            : `${PROFILE}/${profile?.hogwartsHouse?.toLowerCase()}`;
        return (<>
        <Loaders type={loaderTypes.timeturner}/>
        <Redirect url={redirectUrl}/>
      </>);
    }
    return (<div className={s.root}>
      <HeadMeta title="Official Hogwarts House Sorting Quiz" canonicalUrl={SORTING_HAT} ogTitle={metaData.ogTitle} ogDescription={metaData.ogDescription}/>
      <SegmentPage key="SortingHatIntro" pageName={pageName}/>
      <div className={s.container}>
        <div className={s.imageContainerDesktop}>
          <Image imageSet={optimizeImageSet(sortingHatIMGS)} className={s.image} alt={t('header')}/>
        </div>
        <div className={s.textContainer}>
          <div className={s.logoContainer}>
            <Logo />
          </div>
          <div className={s.textContent}>
            <div className={s.eyebrow}>{t('eyebrow')}</div>
            <div className={s.title}>{t('header')}</div>
            <div className={s.imageContainerMobile}>
              <Image imageSet={optimizeImageSet(sortingHatIMGS)} className={s.image} alt={t('header')}/>
            </div>
            <div className={s.description}>{t('description')}</div>
          </div>
          <div className={s.cta}>
            <AnchorLink href={WW_SORTING_HAT_DIRECT} external title={t('button')}>
              <Button label={t('button')} tabIndex="-1" onClick={() => {
            if (!isLoggedIn)
                setAuthSuccessRedirect(SORTING_HAT_FIRST_QUESTION);
            trackEvent('CTA Clicked', {
                cta_text: t('button'),
                label: buttonLabel,
                location: pageName
            });
        }}/>
            </AnchorLink>
          </div>
          <LanguagePicker pageName={pageName} marginTop={30} marginBottom={30}/>
        </div>
      </div>
    </div>);
};
export default Intro;

    async function __Next_Translate__getStaticProps__1948f157096__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//SortingHatIntro/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1948f157096__ as getStaticProps }
  